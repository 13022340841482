<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Reducción de Cuotas de Crédito - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
         

                <b-col sm="12" md="8">
          
                </b-col>

                
                <b-col sm="6" md="3">
                  <b-form-group label=".">
                    <b-input-group>
                    <b-form-input v-model="search" class="form-control"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="ListCreditFeeReduction"><b-icon icon="search"></b-icon></b-button>
                    </b-input-group-append>
                  </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="6" md="1">
                  <b-form-group label=".">
                    <b-link  v-if="Permission('CreditFeeReductionAdd')" class="btn form-control btn-primary"  :to="{ path: '/reduccion-cuotas/nuevo' }" append ><i class="fas fa-file"></i></b-link>
                  </b-form-group>
                </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="7%" class="text-center">Fecha</th>
                    <th width="40%" class="text-center">Cliente</th>
                    <th width="8%" class="text-center">Codigo</th>
                    <th width="13%" class="text-center">M. Pago</th>
                    <th width="8%" class="text-center">Crédito</th>
                    <th width="7%" class="text-center">Total</th>
                    <th width="8%" class="text-center">Usuario</th>
                    <th width="7%" class="text-center">Estado</th>
                    <th width="7%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date  }}</td>
                    <td class="text-left"> {{ item.client_name  }}</td>
                    <td class="text-center"> {{ item.code  }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method)  }}</td>
                    <td class="text-center"> {{ item.credit_code  }}</td>
                    <td class="text-right"> {{ item.total  }}</td>
                    <td class="text-left"> {{ item.user  }}</td>
                    <td class="text-center">
                        <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                        <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('CreditFeeReductionEdit')" @click="EditCreditFeeReduction(item.id_credit_fee_reduction)" >Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('CreditFeeReductionView')" @click="ViewCreditFeeReduction(item.id_credit_fee_reduction)" >Ver</b-dropdown-item>
                        <b-dropdown-item  v-if="Permission('CreditFeeReductionDelete')" @click="ConfirmDeleteCreditFeeReduction(item.id_credit_fee_reduction)" >Anular</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListCreditFeeReduction" :total-rows="rows" :per-page="perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
export default {
  name: "CreditPaymentList",
  components:{
    vSelect,
  },
  data() {
    return {
      module:'CreditFeeReduction',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],
      search: "",
    };
  },
  mounted() {
    this.ListCreditFeeReduction();
  },
  methods: {
    ListCreditFeeReduction,
    EditCreditFeeReduction,
    ViewCreditFeeReduction,
    ConfirmDeleteCreditFeeReduction,
    DeleteCreditFeeReduction,
    Permission,
    NameMethodPayment,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function NameMethodPayment(code) {
  return CodeToName.NameMethodPayment(code);
}

function ListCreditFeeReduction() {

  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "credit-fee-reduction/list/"+this.user.id_branch_office+"/"+ search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditCreditFeeReduction(id_credit_fee_reduction) {
  this.$router.push({
    name: "CreditFeeReductionEdit",
    params: { id_credit_fee_reduction: je.encrypt(id_credit_fee_reduction) },
  });
}

// Ver Usuario
function ViewCreditFeeReduction(id_credit_fee_reduction) {
  this.$router.push({
    name: "CreditFeeReductionView",
    params: { id_credit_fee_reduction: je.encrypt(id_credit_fee_reduction) },
  });
}

// Confirmar eliminar
function ConfirmDeleteCreditFeeReduction(id_credit_fee_reduction) {
  Swal.fire({
    title: "Esta seguro de anular la reducción de la cuota?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteCreditFeeReduction(id_credit_fee_reduction);
    }
  });
}

// eliminar usuario
function DeleteCreditFeeReduction(id_credit_fee_reduction) {
  let me = this;
  let url = this.url_base + "credit-fee-reduction/cancel/" + id_credit_fee_reduction;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token, module: this.module,role: 4,},
  })
    .then(function (response) {
       if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_credit_fee_reduction).indexOf(response.data.result.id_credit_fee_reduction);
        me.data_table[index].state = 0;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>